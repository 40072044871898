





















































import { Component } from 'vue-property-decorator'
import { TaskModel } from '@technology/collaborate-next-js/dist/types/models'
import { TaskStatus } from '@technology/collaborate-next-js'
import Vue from 'vue'

import CAssigneesDisplay from '~/components/dashboard/widget/collaborate/common/displays/AssigneesDisplay.vue'
import CBoardDisplay from '~/components/dashboard/widget/collaborate/common/displays/BoardDisplay.vue'
import CContactInfoDisplay from '~/components/dashboard/widget/collaborate/common/displays/ContactInfoDisplay.vue'
import CDescriptionDisplay from '~/components/dashboard/widget/collaborate/common/displays/DescriptionDisplay.vue'
import CEventDateDisplay from '~/components/dashboard/widget/collaborate/common/displays/EventDateDisplay.vue'
import CLocationDisplay from '~/components/dashboard/widget/collaborate/common/displays/LocationDisplay.vue'
import CRequesterDisplay from '~/components/dashboard/widget/collaborate/common/displays/RequesterDisplay.vue'
import CSectionDisplay from '~/components/dashboard/widget/collaborate/common/displays/SectionDisplay.vue'
import CTaskTitleDisplay from '~/components/dashboard/widget/collaborate/common/displays/TaskTitleDisplay.vue'
import CancelledChip from '~/components/dashboard/widget/collaborate/common/displays/CancelledChip.vue'
import CloseHeader from '~/components/common/widget/dialogs/CloseHeader.vue'
import RejectedChip from '~/components/dashboard/widget/collaborate/common/displays/RejectedChip.vue'
import TitleDisplay from '~/components/common/widget/displays/TitleDisplay.vue'
import eventhub from '~/components/dashboard/widget/collaborate/assigneeManagementWidget/eventhub'

@Component({
  components: {
    CancelledChip,
    CAssigneesDisplay,
    CBoardDisplay,
    CContactInfoDisplay,
    CDescriptionDisplay,
    CEventDateDisplay,
    CLocationDisplay,
    CloseHeader,
    CRequesterDisplay,
    CSectionDisplay,
    CTaskTitleDisplay,
    RejectedChip,
    TitleDisplay,
  },
  name: 'dashboard-collaborate-assignee-management-widget-view-details-dialog',
})
export default class Dialog extends Vue {
  public dialog = false
  public task: Nullable<TaskModel> = null
  public config: Nullable<KeyMap<any>> = null

  get showCancelledChip() {
    return this.task && this.task.status === TaskStatus.Cancelled
  }

  get showRejectedChip() {
    return this.task && this.task.status === TaskStatus.Rejected
  }

  get group() {
    if (this.config) {
      return this.$store.myGetters['accounts/group/getById'](this.config.groupId)
    }
    return undefined
  }

  get groupName() {
    return this.group ? this.group.name : ''
  }

  public handleClose() {
    this.dialog = false
  }

  public created() {
    eventhub.$on(
      'open-view-details-dialog',
      ({ config, task }: { config: KeyMap<any>; task: TaskModel }) => {
        this.dialog = true
        this.task = task
        this.config = config
      },
    )
  }

  public beforeDestroy() {
    eventhub.$off('open-view-details-dialog')
  }
}
