
























































import { Component, Prop } from 'vue-property-decorator'
import { TaskModel } from '@technology/collaborate-next-js/dist/types/models'
import { TaskStatus } from '@technology/collaborate-next-js'
import { isAfter } from 'date-fns'
import Vue from 'vue'

import constant from '~/components/dashboard/widget/collaborate/common/constant'

import CancelledChip from '~/components/dashboard/widget/collaborate/common/displays/CancelledChip.vue'
import DataIterator from '~/components/common/DataIterator.vue'
import RejectedChip from '~/components/dashboard/widget/collaborate/common/displays/RejectedChip.vue'
import TextClamp from '~/components/common/TextClamp.vue'

import CAssigneesDisplay from '~/components/dashboard/widget/collaborate/common/displays/AssigneesDisplay.vue'
import CBoardDisplay from '~/components/dashboard/widget/collaborate/common/displays/BoardDisplay.vue'
import CDescriptionDisplay from '~/components/dashboard/widget/collaborate/common/displays/DescriptionDisplay.vue'
import CEventDateDisplay from '~/components/dashboard/widget/collaborate/common/displays/EventDateDisplay.vue'
import CIssueDateDisplay from '~/components/dashboard/widget/collaborate/common/displays/IssueDateDisplay.vue'
import CSectionDisplay from '~/components/dashboard/widget/collaborate/common/displays/SectionDisplay.vue'
import CTaskTitleDisplay from '~/components/dashboard/widget/collaborate/common/displays/TaskTitleDisplay.vue'

import AssigneeManagementDataHandler from '~/components/dashboard/widget/collaborate/assigneeManagementWidget/content/DataHandler.vue'

import eventhub from '~/components/dashboard/widget/collaborate/assigneeManagementWidget/eventhub'
@Component({
  components: {
    CancelledChip,
    CAssigneesDisplay,
    CBoardDisplay,
    CDescriptionDisplay,
    CIssueDateDisplay,
    CEventDateDisplay,
    CSectionDisplay,
    AssigneeManagementDataHandler,
    CTaskTitleDisplay,
    DataIterator,
    RejectedChip,
    TextClamp,
  },
  name: 'dashboard-collaborate-assignee-management-widget-content-card-listing',
})
export default class CardListing extends Vue {
  @Prop() public config: KeyMap<any>
  @Prop() public dataIds: string[]
  @Prop() public allDataIds: string[]
  @Prop() public dateType: string

  get isShowEventDate() {
    return this.dateType === constant.dateTypes.eventDate.value
  }

  public getCorrespondingTaskStyle(task: TaskModel) {
    const classes = []
    const parentTask = this.$store.myGetters.getParentTaskById(task.id)
    // Either rejected or passed
    if (this.checkTaskIsCancelled(task)) {
      classes.push('assignee-management-card-listing__column-row--cancelled')
    } else if (this.checkTaskIsRejected(task)) {
      classes.push('assignee-management-card-listing__column-row--rejected')
      // Use start date time
    } else if (this.isShowEventDate && isAfter(new Date(), task.endDatetime)) {
      classes.push('assignee-management-card-listing__column-row--passed')
      // Use issue date
    } else if (
      !this.isShowEventDate &&
      isAfter(new Date().setHours(0, 0, 0, 0), parentTask.articleIssueDate)
    ) {
      classes.push('assignee-management-card-listing__column-row--passed')
    }
    return classes
  }

  public handleLongPressClick(task: TaskModel) {
    return () => {
      eventhub.$emit('open-view-details-dialog', {
        config: this.config,
        task,
      })
    }
  }

  public checkTaskIsCancelled(task: TaskModel) {
    return task.status === TaskStatus.Cancelled
  }

  public checkTaskIsRejected(task: TaskModel) {
    return task.status === TaskStatus.Rejected
  }

  public mounted() {
    this.$emit('init')
  }
}
