










import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

import TitleDisplay from '~/components/common/widget/displays/TitleDisplay.vue'

@Component({
  name: 'dashboard-collaborate-assignee-management-widget-header',
  components: {
    TitleDisplay,
  },
})
export default class Header extends Vue {
  @Prop()
  public config: KeyMap<any>

  get group() {
    return this.$store.myGetters['accounts/group/getById'](this.config.groupId)
  }

  get groupName() {
    return this.group ? this.group.name : ''
  }
}
