































































import { Component, Prop } from 'vue-property-decorator'
import { TaskStatus } from '@technology/collaborate-next-js'
import Vue from 'vue'

import AssigneeManagementCardListing from '~/components/dashboard/widget/collaborate/assigneeManagementWidget/content/CardListing.vue'
import AssigneeManagementDataListing from '~/components/dashboard/widget/collaborate/assigneeManagementWidget/content/DataListing.vue'
import Collapse from '~/components/common/Collapse.vue'
import CounterDisplay from '~/components/common/widget/displays/CounterDisplay.vue'
import util from '~/util'

@Component({
  components: {
    AssigneeManagementCardListing,
    AssigneeManagementDataListing,
    Collapse,
    CounterDisplay,
  },
  name: 'dashboard-collaborate-assignee-management-widget-content',
})
export default class Content extends Vue {
  @Prop() public config: KeyMap<any>
  @Prop() public dataIds: string[]
  @Prop() public dateType: string

  public wrapperElement: any = null
  public calcTargetElement: any = null
  public cancelledSelected = false
  public rejectedSelected = false
  public $refs: any

  public filterFunctionChain: KeyMap<(chainedDataIds: string[], dataIds: string[]) => string[]> = {}

  get isMobile() {
    return this.$store.myGetters.getIsMobile
  }

  get widgetGroup() {
    return this.$store.myGetters['accounts/group/getById'](this.config.groupId)
  }

  get tasks() {
    return this.$store.myGetters.getTasksByIds(this.dataIds)
  }

  get filteredDataIds() {
    const taskIds = this.tasks.map(task => task.id)
    // Recalculate the collapse height
    this.$refs.collapseRef && this.$nextTick(this.$refs.collapseRef.recalculateHeight)
    if (Object.keys(this.filterFunctionChain).length) {
      // Put assignee to the last
      const keysOrdering = Object.keys(this.filterFunctionChain).sort((keyA, keyB) => {
        if (keyB === 'assignee') {
          return -1
        } else if (keyA === 'assignee') {
          return 1
        }
        return keyA > keyB ? 1 : keyA < keyB ? -1 : 0
      })
      const filteredResult = keysOrdering.reduce(
        (filteredDataIds: string[], keys) =>
          this.filterFunctionChain[keys](filteredDataIds, taskIds),
        [],
      )
      // Remove duplicated value
      return util.distinct(filteredResult)
    }
    return taskIds
  }

  get cancelledTaskCount() {
    return this.tasks.filter(task => task.status === TaskStatus.Cancelled).length
  }

  get rejectedTaskCount() {
    return this.tasks.filter(task => task.status === TaskStatus.Rejected).length
  }

  get totalTaskCount() {
    return this.tasks.length
  }

  get totalTaskSelected() {
    return !this.cancelledSelected && !this.rejectedSelected
  }

  public handleCollapseChange(collapseState: number) {
    this.$emit('config-update', {
      ...this.config,
      collapseState,
    })
  }

  public handleAssigneeChange(assigneeIds: string[]) {
    if (assigneeIds.length) {
      Vue.set(
        this.filterFunctionChain,
        'assignee',
        (chainedDataIds: string[], dataIds: string[]) => {
          const assigneeTasks = this.$store.myGetters
            .getTasksByIds(dataIds)
            .filter(task => task.assigneeIds?.some(assigneeId => assigneeIds.includes(assigneeId)))
          // If it has another filter function, get the duplicate id
          if (Object.keys(this.filterFunctionChain).length > 1) {
            return assigneeTasks
              .filter(task => chainedDataIds.includes(task.id))
              .map(task => task.id)
          }
          // If not, filter all tasks by the assignees
          return assigneeTasks.map(task => task.id)
        },
      )
    } else {
      Vue.delete(this.filterFunctionChain, 'assignee')
    }
  }

  public handleAllClick() {
    this.cancelledSelected = false
    this.rejectedSelected = false
    Vue.delete(this.filterFunctionChain, 'cancelled')
    Vue.delete(this.filterFunctionChain, 'rejected')
  }

  public handleCancelledClick() {
    this.cancelledSelected = !this.cancelledSelected
    if (this.cancelledSelected) {
      Vue.set(
        this.filterFunctionChain,
        'cancelled',
        (chainedDataIds: string[], dataIds: string[]) =>
          chainedDataIds.concat(
            this.$store.myGetters
              .getTasksByIds(dataIds)
              .filter(task => task.status === TaskStatus.Cancelled)
              .map(task => task.id),
          ),
      )
    } else {
      Vue.delete(this.filterFunctionChain, 'cancelled')
    }
  }

  public handleRejectedClick() {
    this.rejectedSelected = !this.rejectedSelected
    if (this.rejectedSelected) {
      Vue.set(this.filterFunctionChain, 'rejected', (chainedDataIds: string[], dataIds: string[]) =>
        chainedDataIds.concat(
          this.$store.myGetters
            .getTasksByIds(dataIds)
            .filter(task => task.status === TaskStatus.Rejected)
            .map(task => task.id),
        ),
      )
    } else {
      Vue.delete(this.filterFunctionChain, 'rejected')
    }
  }

  public handleDataPresentInit() {
    const wrapperElements = this.isMobile
      ? this.$refs.dataPresentRef.$el.getElementsByClassName('v-data-iterator')
      : this.$refs.dataPresentRef.$el.getElementsByClassName('v-data-table')
    const calcTargetElements = this.isMobile
      ? this.$refs.dataPresentRef.$el.getElementsByClassName('v-data-content')
      : this.$refs.dataPresentRef.$el.getElementsByClassName('v-data-table__wrapper')
    if (wrapperElements.length && calcTargetElements.length) {
      this.wrapperElement = wrapperElements[0]
      this.calcTargetElement = calcTargetElements[0]
      if (this.wrapperElement && this.calcTargetElement) {
        // Recalculate the collapse height
        this.$refs.collapseRef && this.$nextTick(this.$refs.collapseRef.recalculateHeight)
      }
    }
  }
}
