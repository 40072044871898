






import { Component } from 'vue-property-decorator'
import Vue from 'vue'

import AssigneeManagementAddWidgetDialog from '~/components/dashboard/widget/collaborate/assigneeManagementWidget/addWidgetDialog/Dialog.vue'
import AssigneeManagementViewDetailsDialog from '~/components/dashboard/widget/collaborate/assigneeManagementWidget/viewDetailsDialog/Dialog.vue'

@Component({
  components: {
    AssigneeManagementAddWidgetDialog,
    AssigneeManagementViewDetailsDialog,
  },
  name: 'dashboard-collaborate-assignee-management-widget-placeholder',
})
export default class Placeholder extends Vue {}
