var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('assignee-management-data-handler',{attrs:{"all-data-ids":_vm.allDataIds,"data-ids":_vm.dataIds,"config":_vm.config},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var listItems = ref.listItems;
var checkFieldCanShow = ref.checkFieldCanShow;
return [_c('data-iterator',{attrs:{"items":listItems},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('v-flex',{directives:[{name:"long-press",rawName:"v-long-press",value:(_vm.handleLongPressClick(props.item)),expression:"handleLongPressClick(props.item)"}],attrs:{"xs12":""}},[_c('v-card',{class:_vm.getCorrespondingTaskStyle(props.item),attrs:{"text":""}},[_c('v-card-text',{staticClass:"pb-0"},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticClass:"assignee-management-card-listing__column-row-event-time"},[(_vm.isShowEventDate)?[(checkFieldCanShow("TaskModel.startDatetime"))?_c('c-event-date-display',{attrs:{"task":props.item}}):_vm._e()]:[(checkFieldCanShow("TaskModel.endDatetime"))?_c('c-issue-date-display',{attrs:{"format-str":"DD-MMM","task":props.item}}):_vm._e()],_c('c-section-display',{staticClass:"ml-2",attrs:{"task":props.item}})],2),_c('v-spacer'),_c('v-flex',{staticClass:"assignee-management-card-listing__column-row-board",attrs:{"shrink":""}},[_c('c-board-display',{attrs:{"task":props.item}})],1)],1)],1),_c('v-list',{staticClass:"pb-0"},[(checkFieldCanShow("TaskModel.title"))?_c('v-list-item',{staticClass:"assignee-management-card-listing__column-row-title"},[_c('v-list-item-content',[_c('c-task-title-display',{staticClass:"assignee-management-card-listing__title",attrs:{"task":props.item},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var displayText = ref.displayText;
return [_c('text-clamp',{attrs:{"height":"30px","value":displayText}})]}}],null,true)})],1),(_vm.checkTaskIsRejected(props.item))?[_c('rejected-chip',{staticClass:"ml-2"})]:(_vm.checkTaskIsCancelled(props.item))?[_c('cancelled-chip',{staticClass:"ml-2"})]:_vm._e()],2):_vm._e()],1),(checkFieldCanShow("TaskModel.assigneeIds"))?_c('v-card-title',{staticClass:"assignee-management-card-listing__column-row-assignee py-0"},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"grow":""}},[_c('c-assignees-display',{attrs:{"task":props.item}})],1)],1)],1):_vm._e(),(checkFieldCanShow("TaskModel.description"))?_c('v-list',[_c('v-list-item',{staticClass:"assignee-management-card-listing__column-row-description"},[_c('v-list-item-content',[_c('v-list-item-subtitle',[_c('c-description-display',{staticClass:"assignee-management-card-listing__description",attrs:{"task":props.item},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var description = ref.description;
var click = ref.click;
return [_c('text-clamp',{attrs:{"height":"25px","value":description}},[_c('span',{on:{"click":click}},[_vm._v(_vm._s(description))])])]}}],null,true)})],1)],1)],1)],1):_vm._e(),_c('v-divider')],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }