





import { Component, Prop } from 'vue-property-decorator'
import { GroupConfigKey } from '@technology/collaborate-next-js'
import { User as UserModel } from '@technology/accounts-js/dist/types/models/user'
import Vue from 'vue'

@Component({
  name: 'dashboard-collaborate-assignee-management-widget-content-data-handler',
})
export default class DataHandler extends Vue {
  @Prop() public config: KeyMap<any>
  @Prop() public dataIds: string[]
  @Prop() public allDataIds: string[]

  get slotProps() {
    return {
      assignees: this.assignees,
      listItems: this.listItems,
      checkFieldCanShow: this.checkFieldCanShow,
    }
  }

  get assignees() {
    const groupUsers = this.$store.myGetters['accounts/groupUser/getListByGroupId'](
      this.config.groupId,
    )
    return groupUsers
      .map(
        groupUser => this.$store.myGetters['accounts/user/getById'](groupUser.userId) as UserModel,
      )
      .filter(user => !!user)
      .sort((a: UserModel, b: UserModel) => {
        const aName = a.displayName.toLowerCase()
        const bName = b.displayName.toLowerCase()
        return aName > bName ? 1 : aName < bName ? -1 : 0
      })
  }

  get listItems() {
    return this.$store.myGetters.getTasksByIds(this.dataIds)
  }

  get hiddenFields() {
    const formHiddenFields: any = this.$store.myGetters.getGroupConfigByGroupIdAndKey(
      this.config.groupId,
      GroupConfigKey.FormHiddenFields,
    )
    return formHiddenFields ? formHiddenFields : []
  }

  public checkFieldCanShow(key: string) {
    return this.hiddenFields.indexOf(key) === -1
  }
}
