








































































import { Component, Prop } from 'vue-property-decorator'
import { TaskModel } from '@technology/collaborate-next-js/dist/types/models'
import { TaskStatus } from '@technology/collaborate-next-js'
import { isAfter } from 'date-fns'
import Vue from 'vue'

import constant from '~/components/dashboard/widget/collaborate/common/constant'

import CAssigneeHeader from '~/components/dashboard/widget/collaborate/common/displays/AssigneeHeader.vue'
import CAssigneesDisplay from '~/components/dashboard/widget/collaborate/common/displays/AssigneesDisplay.vue'
import CBoardDisplay from '~/components/dashboard/widget/collaborate/common/displays/BoardDisplay.vue'
import CContactInfoDisplay from '~/components/dashboard/widget/collaborate/common/displays/ContactInfoDisplay.vue'
import CEventDateDisplay from '~/components/dashboard/widget/collaborate/common/displays/EventDateDisplay.vue'
import CIssueDateDisplay from '~/components/dashboard/widget/collaborate/common/displays/IssueDateDisplay.vue'
import CLocationDisplay from '~/components/dashboard/widget/collaborate/common/displays/LocationDisplay.vue'
import CRequesterDisplay from '~/components/dashboard/widget/collaborate/common/displays/RequesterDisplay.vue'
import CSectionDisplay from '~/components/dashboard/widget/collaborate/common/displays/SectionDisplay.vue'
import CTaskWrapperDisplay from '~/components/dashboard/widget/collaborate/common/displays/TaskWrapperDisplay.vue'

import AssigneeManagementDataHandler from '~/components/dashboard/widget/collaborate/assigneeManagementWidget/content/DataHandler.vue'
@Component({
  components: {
    CAssigneesDisplay,
    CAssigneeHeader,
    CBoardDisplay,
    CContactInfoDisplay,
    CIssueDateDisplay,
    CEventDateDisplay,
    CLocationDisplay,
    CRequesterDisplay,
    CSectionDisplay,
    AssigneeManagementDataHandler,
    CTaskWrapperDisplay,
  },
  name: 'dashboard-collaborate-assignee-management-widget-content-data-listing',
})
export default class DataListing extends Vue {
  @Prop() public config: KeyMap<any>
  @Prop() public dataIds: string[]
  @Prop() public allDataIds: string[]
  @Prop() public dateType: string

  public listHeaders: any = [
    {
      text: 'Date',
      sortable: false,
      width: '150px',
    },
    {
      text: 'Border',
      sortable: false,
      value: '',
      width: '5%',
    },
    {
      text: 'Section',
      sortable: false,
      value: '',
      width: '5%',
    },
    {
      text: 'Task',
      sortable: false,
      value: 'title',
      width: '30%',
    },
    {
      text: 'Assignee',
      sortable: false,
      value: 'assigneeIds',
      width: '15%',
    },
    {
      text: 'Location',
      sortable: false,
      value: 'location',
      width: '20%',
    },
    {
      text: 'Requester',
      sortable: false,
      value: '',
      width: '10%',
    },
    {
      text: 'Contact Info',
      sortable: false,
      value: 'contactInfo',
    },
  ]

  public assigneeIds = []

  get isShowEventDate() {
    return this.dateType === constant.dateTypes.eventDate.value
  }

  public getCorrespondingTaskStyle(task: TaskModel) {
    const classes = []
    const targetTask =
      task.source === 'shared' ? task : this.$store.myGetters.getParentTaskById(task.id)
    // Either rejected or passed
    if (task.status === TaskStatus.Cancelled) {
      classes.push('assignee-management-data-listing__column-row--cancelled')
    } else if (task.status === TaskStatus.Rejected) {
      classes.push('assignee-management-data-listing__column-row--rejected')
      // Use start date time
    } else if (this.isShowEventDate && isAfter(new Date(), task.endDatetime)) {
      classes.push('assignee-management-data-listing__column-row--passed')
      // Use issue date
    } else if (
      !this.isShowEventDate &&
      isAfter(new Date().setHours(0, 0, 0, 0), targetTask.articleIssueDate)
    ) {
      classes.push('assignee-management-data-listing__column-row--passed')
    }
    return classes
  }

  /**
   * using slot inside scoped slot will cause unexpected error
   */
  public handleAssigneeChange(value: string[]) {
    this.$emit('assignee-change', value)
  }

  public mounted() {
    this.$emit('init')
  }
}
