









































































































































import { Component, Prop, Watch } from 'vue-property-decorator'
import { SearchWithNestedParams } from '@technology/collaborate-next-js/dist/types/modules/task-search/params'
import { TaskModel } from '@technology/collaborate-next-js/dist/types/models'
import { endOfDay, format, isAfter, isEqual, startOfDay } from 'date-fns'
import Vue from 'vue'
import constant from '~/components/dashboard/widget/collaborate/common/constant'

import CloseButton from '~/components/common/widget/buttons/CloseButton.vue'
import CustomButtonGroup from '~/components/common/CustomButtonGroup.vue'
import DateButtonDisplay from '~/components/common/widget/displays/DateButtonDisplay.vue'
import DateControl from '~/components/common/widget/controls/DateControl.vue'
import MobileWrapper from '~/components/common/widget/wrappers/MobileWrapper.vue'
import Preload from '~/components/common/Preload.vue'
import RefreshButton from '~/components/common/widget/buttons/RefreshButton.vue'
import TodayButton from '~/components/common/widget/buttons/TodayButton.vue'
import WidgetNameDisplay from '~/components/common/widget/displays/WidgetNameDisplay.vue'

import CBoardMobileDisplay from '~/components/dashboard/widget/collaborate/common/displays/BoardMobileDisplay.vue'
import CBoardSelect from '~/components/dashboard/widget/collaborate/common/inputs/BoardSelect.vue'
import CDateTypeSelect from '~/components/dashboard/widget/collaborate/common/inputs/DateTypeSelect.vue'

import AssigneeManagementContent from '~/components/dashboard/widget/collaborate/assigneeManagementWidget/content/Content.vue'
import AssigneeManagementHeader from '~/components/dashboard/widget/collaborate/assigneeManagementWidget/header/Header.vue'

import { WidgetConfigEntity } from '~/apollo/types'
import { dateFormat } from '~/filters'
import util from '~/util'
@Component({
  components: {
    CBoardMobileDisplay,
    CBoardSelect,
    CDateTypeSelect,
    CloseButton,
    CustomButtonGroup,
    AssigneeManagementContent,
    AssigneeManagementHeader,
    DateControl,
    DateButtonDisplay,
    MobileWrapper,
    Preload,
    RefreshButton,
    TodayButton,
    WidgetNameDisplay,
  },
  filters: {
    dateFormat,
  },
  name: 'dashboard-collaborate-assignee-management-widget',
})
export default class TaskAssignManagementWidget extends Vue {
  @Prop() public widget: WidgetConfigEntity
  @Prop() public widgetId: string
  @Prop() public config: KeyMap<any>

  public refreshInterval = 30000
  public enableCronjob = true

  public cancelLoading = false
  public dataLoading = false
  public refreshLoading = false
  public fetchDatetime = new Date()
  public dataIds: string[] = []
  public dateType: string = constant.dateTypes.eventDate.value
  public intervalId: NodeJS.Timeout

  public widgetStartDate: string = dateFormat(new Date(), 'YYYY-MM-DD')
  public widgetEndDate: string = dateFormat(new Date(), 'YYYY-MM-DD')
  public startDateSelected = false
  public endDateSelected = false

  get isMobile() {
    return this.$store.myGetters.getIsMobile
  }

  public handleCloseBtnClick() {
    this.$alertDialog.showDialog({
      title: 'Warning',
      message: 'Are you sure to remove this widget?',
      buttons: [
        {
          text: 'Yes',
          color: 'primary',
          onClick: async () => {
            this.cancelLoading = true
            await this.$store.myActions.deleteWidgetConfig({
              widgetConfigId: this.widgetId,
            })
            this.cancelLoading = false
          },
        },
        {
          text: 'Cancel',
        },
      ],
    })
  }

  public async handleConfigUpdate(config: KeyMap<any>) {
    await this.$store.myActions.updateWidgetConfig({
      widgetConfigId: this.widgetId,
      body: {
        config: {
          ...config,
        },
      },
    })
  }

  public async handleBoardChange(boardId: string) {
    await this.handleConfigUpdate({
      ...this.config,
      boardId,
    })
    this.fetchData(true, this.getFetchBody(boardId))
  }

  public async handleRefreshBtnClick() {
    this.sendGAAction('event', 'TAM', 'Click', 'Refresh')
    this.refreshLoading = true
    await this.fetchData(false)
    this.refreshLoading = false
  }

  public handleDateTypeChange(dateType: string) {
    this.dateType = dateType
    this.fetchData()
  }

  @Watch('config')
  public onConfigChanged(to: KeyMap<any>, from: KeyMap<any>) {
    if (to.boardId !== from.boardId) {
      this.fetchData()
    }
  }

  // TODO: wait for vuetify date-range component
  // refs: https://github.com/vuetifyjs/vuetify/issues/1646
  // Date range support
  public handleTodayClick() {
    const today = dateFormat(new Date(), 'YYYY-MM-DD')
    this.widgetStartDate = dateFormat(new Date(), 'YYYY-MM-DD')
    this.replaceEndDateIfAfter(today)
    this.startDateSelected = false
    this.fetchData()
  }

  public handleStartDateClose() {
    this.startDateSelected = false
  }

  public handleStartDateChange(date: string) {
    this.widgetStartDate = date
    this.replaceEndDateIfAfter(date)
    this.startDateSelected = false
    this.fetchData()
  }

  public handleStartDateClick() {
    this.startDateSelected = true
    this.endDateSelected = false
  }

  public handleEndDateClose() {
    this.endDateSelected = false
  }

  public handleEndDateChange(date: string) {
    this.widgetEndDate = date
    this.endDateSelected = false
    this.fetchData()
  }

  public handleEndDateClick() {
    this.startDateSelected = false
    this.endDateSelected = true
  }

  public endDateAllowedDates(date: string) {
    const dateWithoutTime = format(date, 'YYYY-MM-DD')
    return (
      isAfter(dateWithoutTime, this.widgetStartDate) ||
      isEqual(dateWithoutTime, this.widgetStartDate)
    )
  }

  public replaceEndDateIfAfter(targetDate: string) {
    if (isAfter(targetDate, this.widgetEndDate)) {
      this.widgetEndDate = targetDate
    }
  }
  // end

  /**
   * Fetch task assignment widget data from server
   */
  public async fetchData(withLoading = true, body = this.getFetchBody()) {
    this.clearBackgroundJob()
    withLoading && (this.dataLoading = true)
    try {
      const result = await this.$store.myActions.fetchTaskAssignManagement({
        ...body,
      })
      let taskArray = result.data
      if (result.nested && result.nested.relatedTasks) {
        taskArray = [...taskArray, ...result.nested.relatedTasks]
      }
      await Promise.all([
        this.fetchRelatedUser(taskArray),
        this.fetchGroupUser(),
        this.fetchGroupConfigs(this.config.groupId),
      ])
      // Sort by event datetime
      this.dataIds = result.data
        .sort((a: TaskModel, b: TaskModel) => {
          const parentTaskA = this.$store.myGetters.getParentTaskById(a.id)
          const parentTaskB = this.$store.myGetters.getParentTaskById(b.id)
          const dateTimeA = new Date(
            this.dateType === constant.dateTypes.pubDate.value
              ? parentTaskA.articleIssueDate
              : a.startDatetime,
          ).getTime()
          const dateTImeB = new Date(
            this.dateType === constant.dateTypes.pubDate.value
              ? parentTaskB.articleIssueDate
              : b.startDatetime,
          ).getTime()
          if (dateTimeA < dateTImeB) {
            return -1
          }
          if (dateTimeA > dateTImeB) {
            return 1
          }
          return 0
        })
        .map(task => task.id)
      this.fetchDatetime = new Date()
    } catch (e) {
      const err = e as any
      if (util.isObject(err.body)) {
        const { code, message } = err.body
        this.$store.myActions.SHOW_DIALOG({
          app: 'collaborate',
          type: 'error',
          code,
          description: message,
        })
      } else {
        console.log(err)
      }
    }
    withLoading && (this.dataLoading = false)
    this.enableCronjob && this.setNextJob()
  }

  public async fetchRelatedUser(tasks: TaskModel[]) {
    const assigneeIds = util
      .distinct(util.flatten(tasks.map(task => [...(task.assigneeIds || []), task.createdBy])))
      .filter(
        assigneeId =>
          !util.checkEmpty(assigneeId) &&
          !this.$store.myGetters['accounts/user/getById'](assigneeId),
      )
    if (assigneeIds.length > 0) {
      return this.$store.myActions['accounts/user/fetchList']({
        q: assigneeIds.join(','),
        type: 'ids',
      })
    }
  }

  public async fetchGroupConfigs(groupId: string) {
    if (util.checkEmpty(this.$store.myGetters.getGroupConfigByGroupId(groupId))) {
      return this.$store.myActions.fetchGroupConfigs()
    }
  }

  public async fetchGroupUser() {
    return this.$store.myActions['accounts/groupUser/fetchList']({
      active: true,
      groupId: this.config.groupId,
    })
  }

  // Background job related
  public clearBackgroundJob() {
    this.intervalId && clearTimeout(this.intervalId)
  }

  public setNextJob() {
    this.intervalId = setTimeout(async () => {
      this.refreshLoading = true
      await this.fetchData(false)
      this.refreshLoading = false
    }, this.refreshInterval)
  }

  public async created() {
    this.sendGAAction('event', 'TAM', 'Load')
    await this.fetchData(false)
  }

  public destroyed() {
    this.enableCronjob = false
    this.clearBackgroundJob()
  }

  private getFetchBody(boardId: string = '') {
    const baseBody: SearchWithNestedParams = {
      taskSearch: {
        groupIds: [this.config.groupId],
      },
    }
    const localBoardId = boardId || this.config.boardId
    if (localBoardId && localBoardId !== 'all') {
      baseBody.taskSearch.boardIds = [localBoardId]
    }
    if (this.dateType === constant.dateTypes.eventDate.value) {
      baseBody.taskSearch.eventDateStart = startOfDay(this.widgetStartDate).toISOString()
      baseBody.taskSearch.eventDateEnd = endOfDay(this.widgetEndDate).toISOString()
    } else {
      baseBody.taskSearch.issueDateStart = startOfDay(this.widgetStartDate).toISOString()
      baseBody.taskSearch.issueDateEnd = endOfDay(this.widgetEndDate).toISOString()
    }
    return baseBody
  }
}
